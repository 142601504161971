<template>
  <div class="filter-items">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.filter-items {
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: auto;
  height: 100%;
  flex: 1;
  padding-inline: 1px; // For not truncating the button on focus
  padding-block: 8px;
}
</style>
